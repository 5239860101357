/* @import "../src/assets/fonts/fontawesome-webfont.eot"; */

a.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 180px;
}
img {
  /* width: 100px;
  height: 100px; */

  background-color: grey;
}
.caption {
  display: block;
}   
h1 {
        font-size: 28px;
        color: blue;
    } 
.customer-body {
    background: #f0f0f0; }
  
  .customer-header {
    position: fixed;
    background: #f0f0f0;
    z-index: 999;
    left: 0;
    right: 0; }
  
  .customer-navbar {
    background: #fff; }
    @media (min-width: 992px) {
      .customer-navbar {
        padding-bottom: 0 !important;
        padding-top: 3px !important; } }
    .customer-navbar a {
      color: #282828 !important; }
  
  .xtra-div2 {
    padding-top: 150px; }
    .xtra-div {
    padding-top: 114px; }

    .xtra-div-top-25 {
        padding-top: 35px; }

        .xtra-div-top {
            padding-top: 45px; }


  .icons-header {
    background: #f0f0f0; }
    .icons-header ul {
      padding-left: 0;
      margin-bottom: 0; }
      .icons-header ul li {
        display: inline-block;
        text-align: center; }
        .icons-header ul li a {
          text-transform: uppercase;
          color: #282828;
          font-size: 12px;
          padding: 10px;
          display: block;
          border: 2px solid #f0f0f0; }
          .icons-header ul li a:hover {
            text-decoration: none;
            color: #000;
            border: 2px inset; }
            .icons-header ul li .current {
              text-decoration: none;
              color: #000;
              border: 2px inset; }
          .icons-header ul li a span {
            display: block; }
  
  .customer-form-sec {
    background: #f0f0f0;
    padding-bottom: 30px; }
    .customer-form-sec .form-sec {
      padding-top: 15px;
      padding-bottom: 15px; }
      .customer-form-sec .form-sec label {
        width: 100px;
        text-align: right;
        margin-right: 7px; }
      .customer-form-sec .form-sec .state-sec label {
        float: left; }
      .customer-form-sec .form-sec .state-sec .row {
        padding-left: 4px; }
        .customer-form-sec .form-sec .state-sec .row .zip-sec {
          padding-left: 5px; }
        .customer-form-sec .form-sec .state-sec .row .cstm-input {
          width: 128px; }
      .customer-form-sec .form-sec .label-row label {
        width: 100%;
        text-align: left;
        padding-left: 110px;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 0; }
      .customer-form-sec .form-sec .label-row-2 label {
        width: 100%;
        text-align: left;
        font-weight: 600;
        font-size: 13px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 4px;
        margin-bottom: 10px;
        padding-left: 42px; }
      .customer-form-sec .form-sec .label-row-padd-0 label {
        padding-left: 0; }
        .customer-form-sec .form-sec .label-row-padd-0 label:after {
          content: '';
          border-bottom: 1px solid #ccc;
          width: 94%;
          display: block;
          position: absolute;
          right: 0;
          top: 50%; }
      .customer-form-sec .form-sec .customer-form-btns {
        padding-left: 0;
        margin-bottom: 0; }
        .customer-form-sec .form-sec .customer-form-btns li {
          display: inline-block; }
          .customer-form-sec .form-sec .customer-form-btns li a {
            display: block;
            color: #282828;
            border: 2px outset #ccc;
            padding: 3px 7px;
            text-align: center; }
            .customer-form-sec .form-sec .customer-form-btns li a:hover {
              text-decoration: none;
              border: 2px inset #ccc; }
            .customer-form-sec .form-sec .customer-form-btns li a span {
              display: block; }
      .customer-form-sec .form-sec .inline-blk {
        float: left; }
      .customer-form-sec .form-sec .ph-btns {
        float: left;
        padding-left: 5px;
        margin-bottom: 0; }
        .customer-form-sec .form-sec .ph-btns li {
          display: inline-block;
          background: #f0f0f0; }
          .customer-form-sec .form-sec .ph-btns li a {
            color: #282828;
            padding: 2px;
            display: block;
            border: 2px outset #ccc; }
            .customer-form-sec .form-sec .ph-btns li a:hover {
              text-decoration: none;
              border: 2px inset #ccc; }
    .customer-form-sec .customer-sidebar {
      border-left: 2px solid #d0d0d0;
      padding-top: 15px;
      padding-bottom: 15px; }
      .customer-form-sec .customer-sidebar .inner-sidebar {
        position: fixed; }
        .customer-form-sec .customer-sidebar .inner-sidebar ul {
          padding-left: 0;
          margin-bottom: 0; }
          .customer-form-sec .customer-sidebar .inner-sidebar ul li {
            display: block;
            margin-bottom: 7px; }
            .customer-form-sec .customer-sidebar .inner-sidebar ul li a {
              display: inline-block;
              color: #282828;
              font-size: 16px; }
              .customer-form-sec .customer-sidebar .inner-sidebar ul li a img {
                width: 50px;
                margin-right: 5px; }
  
  .customer-footer {
    float: left;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f0f0f0; }
    .customer-footer ul {
      padding-left: 0;
      margin-bottom: 0;
      float: right; }
      .customer-footer ul li {
        display: inline-block;
        margin-right: 25px; }
        .customer-footer ul li a {
          display: block;
          color: #282828;
          border: 2px inset #f7f7f7;
          padding: 1px 7px; }
          .customer-footer ul li a:hover {
            text-decoration: none;
            border: 2px outset #f7f7f7; }
  
  .customer-tabs-sec {
    position: fixed;
    background: #f0f0f0;
    left: 0;
    right: 0;
    z-index: 99; }
    .customer-tabs-sec ul {
      padding-left: 0;
      margin-bottom: 0; }
      .customer-tabs-sec ul li {
        display: inline-block; }
        .customer-tabs-sec ul li a {
          display: block;
          color: #282828;
          border: 1px solid #ccc;
          border-radius: 0px 0px 6px 6px;
          padding: 3px 10px;
          border-bottom: 3px solid #ccc; }
          .customer-tabs-sec ul li a:hover {
            text-decoration: none; }
        .customer-tabs-sec ul li .active.show {
          border: 2px outset #ccc;
          border-bottom: 3px outset;
          border-top: 0;
          padding-bottom: 5px; }
  
  .vehicle-form label {
    width: 150px !important; }
  .vehicle-form .cstm-input, .vehicle-form .select-input {
    width: 180px;
    height: 30px;
    padding-left: 3px; }
  .vehicle-form .save-cstm-input {
    width: 156px; }
  .vehicle-form .veh-mile-sec {
    float: left; }
  .vehicle-form .veh-ebay-btn {
    float: left;
    margin-left: 15px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    color: #282828;
    font-weight: 600;
    border: 2px outset;
    padding: 5px; }
    .vehicle-form .veh-ebay-btn:hover {
      text-decoration: none;
      border: 2px inset; }
    .vehicle-form .veh-ebay-btn span {
      display: block; }
  
  #tree1 {
    background: #fff;
    height: 350px; }
  
  .tree-search {
    padding-bottom: 15px; }
    .tree-search .tree-search-input {
      width: 340px;
      outline: none;
      padding-left: 5px; }
    .tree-search #tree-search-btn {
      background: url(/images/search-icon.jpg);
      padding: 2px 12px;
      background-repeat: no-repeat;
      background-position: 50%;
      outline: none;
      position: relative;
      top: 5px; }
  
  .tree-section-container {
    border-top: 1px solid #ccc;
    padding-top: 15px; }
    .tree-section-container .print-btn-sec {
      text-align: right; }
      .tree-section-container .print-btn-sec .cstm-btn {
        width: 100px;
        text-align: center;
        margin-bottom: 10px; }
      .tree-section-container .print-btn-sec .btn-print {
        font-weight: bold; }
    .tree-section-container .print-txt-sec {
      padding-top: 10px; }
      .tree-section-container .print-txt-sec a {
        border: 2px outset;
        display: inline-block;
        padding: 2px; }
        .tree-section-container .print-txt-sec a:hover {
          border: 2px inset; }
  
  .tree-buttons-sec {
    padding-left: 10px; }
    .tree-buttons-sec li {
      display: inline-block;
      margin-right: 10px;
      text-transform: uppercase; }
      .tree-buttons-sec li a {
        color: #282828;
        text-transform: uppercase;
        font-size: 12px;
        display: block;
        text-align: center;
        padding: 5px;
        border: 2px solid #f0f0f0; }
        .tree-buttons-sec li a:hover {
          text-decoration: none;
          border: 2px inset; }
        .tree-buttons-sec li a span {
          display: block; }
    .xtra-div-top2 {
            padding-top: 45px;
            }

  .deal-info-form-sec .left-side-deal-info .form-row {
  margin-bottom: 7px;
  position: relative; }
  .deal-info-form-sec .left-side-deal-info .form-row label {
    width: 130px; }
  .deal-info-form-sec .left-side-deal-info .form-row .cstm-input {
    outline: none;
    padding: 0 5px;
    text-align: right;
    width: 120px; }
  .deal-info-form-sec .left-side-deal-info .form-row .cstm-select {
    margin-left: 7px;
    outline: none;
    width: 128px;
    padding: 0 3px; }
  .deal-info-form-sec .left-side-deal-info .form-row .cstm-arrow-down {
    position: absolute;
    right: 8px;
    top: 8px; }
    .deal-info-form-sec .left-side-deal-info .form-row .cstm-arrow-down:hover {
      cursor: pointer; }
  .deal-info-form-sec .left-side-deal-info .form-row .toggle-dropdown-select {
    position: absolute;
    right: 3px;
    top: 32px;
    z-index: 9;
    display: none; }
  .deal-info-form-sec .left-side-deal-info .form-row .cstm-btn {
    border: 2px outset #ccc;
    background: #f3f3f3;
    font-size: 12px;
    outline: none;
    margin-left: 7px;
    width: 90px; }
    .deal-info-form-sec .left-side-deal-info .form-row .cstm-btn:hover {
      cursor: pointer;
      border: 2px inset #ccc; }
.deal-info-form-sec .right-side-deal-info .pickup-sec {
  border: 1px inset #dad7d7;
  padding: 15px;
  margin-top: 5px;
  position: relative; }
  .deal-info-form-sec .right-side-deal-info .pickup-sec .pick-title {
    margin: 0;
    font-size: 13px;
    background: #f0f0f0;
    position: absolute;
    left: 10px;
    top: -11px;
    padding: 2px 5px; }
  .deal-info-form-sec .right-side-deal-info .pickup-sec table th {
    font-weight: normal;
    text-align: center; }
  .deal-info-form-sec .right-side-deal-info .pickup-sec table td {
    padding-bottom: 7px;
    padding-right: 7px; }
    .deal-info-form-sec .right-side-deal-info .pickup-sec table td .cstm-input {
      outline: none;
      padding: 0 5px;
      width: 100px; }
    .deal-info-form-sec .right-side-deal-info .pickup-sec table td .sec-input {
      text-align: right; }
    .deal-info-form-sec .right-side-deal-info .pickup-sec table td .cstm-val {
      margin-left: 5px; }
.deal-info-form-sec .right-side-deal-info .taxes-sec {
  margin-top: 25px; }
  .deal-info-form-sec .right-side-deal-info .taxes-sec table th {
    font-weight: 500; }
  .deal-info-form-sec .right-side-deal-info .taxes-sec .city-sec {
    position: absolute;
    right: 0;
    bottom: -48px; }
    .deal-info-form-sec .right-side-deal-info .taxes-sec .city-sec .cstm-select {
      width: 185px;
      outline: none; }
.deal-info-form-sec .bottom-row-sec {
  padding-top: 15px; }
  .deal-info-form-sec .bottom-row-sec .btn-sec .inner-sec {
    padding-left: 133px;
    float: left;
    margin-right: 7px; }
    .deal-info-form-sec .bottom-row-sec .btn-sec .inner-sec .cstm-input {
      display: block;
      width: 185px;
      margin-bottom: 7px;
      outline: none;
      padding: 4px 0; }
    .deal-info-form-sec .bottom-row-sec .btn-sec .inner-sec .cstm-btn {
      border: 2px outset #ccc;
      background: #f3f3f3;
      font-size: 13px; }
      .deal-info-form-sec .bottom-row-sec .btn-sec .inner-sec .cstm-btn:hover {
        border: 2px inset #ccc; }
  .deal-info-form-sec .bottom-row-sec .btn-sec .text-area-sec {
    float: left;
    margin-right: 7px; }
    .deal-info-form-sec .bottom-row-sec .btn-sec .text-area-sec .title {
      display: block;
      position: relative;
      bottom: 6px;
      margin-bottom: -6px; }
    .deal-info-form-sec .bottom-row-sec .btn-sec .text-area-sec .txt-area {
      width: 300px;
      height: 91px;
      resize: none;
      outline: none;
      padding: 3px 5px;
      font-size: 13px; }
  .deal-info-form-sec .bottom-row-sec .btn-sec .additional-btn-sec {
    float: left;
    padding-top: 30px; }
    .deal-info-form-sec .bottom-row-sec .btn-sec .additional-btn-sec .cstm-btn {
      border: 2px outset #ccc;
      background: #f3f3f3;
      font-size: 13px;
      width: 100px;
      padding: 5px 0;
      line-height: 17px; }
      .deal-info-form-sec .bottom-row-sec .btn-sec .additional-btn-sec .cstm-btn:hover {
        border: 2px inset #ccc; }

        .notes-history-sec .notes-inner {
  height: 300px; }
  .notes-history-sec .notes-inner table {
    margin: 0; }
    .notes-history-sec .notes-inner table tbody {
      display: block; }
      .notes-history-sec .notes-inner table tbody tr th {
        border: 1px solid #282828;
        padding: 2px 10px;
        font-size: 14px; }
      .notes-history-sec .notes-inner table tbody tr th.date {
        width: 150px; }
      .notes-history-sec .notes-inner table tbody tr th.notes {
        width: 535px;
        text-align: left !important; }
      .notes-history-sec .notes-inner table tbody tr td {
        border: 1px solid #282828; }
      .notes-history-sec .notes-inner table tbody .tr-data:hover {
        background: #3597fd; }
.notes-history-sec .comment-sec {
  padding-top: 10px; }
  .notes-history-sec .comment-sec label {
    display: block;
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 14px; }
  .notes-history-sec .comment-sec .cmnts {
    width: 100%;
    resize: none;
    height: 100px;
    outline: none;
    padding: 0 5px;
    font-size: 14px; }
.notes-history-sec .notes-button-sec {
  padding-left: 0;
  padding-top: 5px; }
  .notes-history-sec .notes-button-sec .notes-btn-sec .cstm-btn {
    display: block;
    width: 100px;
    margin-bottom: 5px; }

#notesModal .modal-dialog {
  width: 600px;
  max-width: 600px; }
  #notesModal .modal-dialog .modal-content {
    border-radius: 0; }
    #notesModal .modal-dialog .modal-content .modal-header {
      padding: 7px 10px; }
      #notesModal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 14px;
        font-weight: normal;
        margin-left: 5px; }
      #notesModal .modal-dialog .modal-content .modal-header .close {
        outline: none; }
    #notesModal .modal-dialog .modal-content .modal-body {
      background: #f0f0f0; }
      #notesModal .modal-dialog .modal-content .modal-body .account-sec .acc-title {
        font-size: 14px; }
      #notesModal .modal-dialog .modal-content .modal-body .account-sec .account-input {
        color: #f00;
        font-weight: bold;
        font-size: 12px;
        outline: none;
        padding: 2px 3px; }
      #notesModal .modal-dialog .modal-content .modal-body .account-sec .acc-srch-btn {
        background: transparent;
        width: 30px;
        height: 26px;
        position: relative;
        top: 4px;
        padding: 0; }
        #notesModal .modal-dialog .modal-content .modal-body .account-sec .acc-srch-btn img {
          max-width: 100%;
          position: relative;
          bottom: 2px; }
        #notesModal .modal-dialog .modal-content .modal-body .account-sec .acc-srch-btn:hover {
          border: 2px inset;
          cursor: pointer; }
      #notesModal .modal-dialog .modal-content .modal-body .notes-tabs {
        padding-top: 15px; }
        #notesModal .modal-dialog .modal-content .modal-body .notes-tabs ul.nav-tabs li a {
          display: block;
          border: 1px solid #ccc;
          border-radius: 3px 3px 0 0;
          padding: 0 4px; }
        #notesModal .modal-dialog .modal-content .modal-body .notes-tabs ul.nav-tabs li .active.show {
          height: 31px;
          position: relative;
          bottom: 3px; }
          #notesModal .modal-dialog .modal-content .modal-body .notes-tabs ul.nav-tabs li .active.show img {
            border: 1px dotted #282828;
            border-radius: 3px; }
        #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content {
          border: 2px outset #f5f5f5;
          padding: 10px;
          height: 170px; }
          #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content .tab-pane .customer-tab {
            border: 1px solid #ccc;
            height: 145px; }
            #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content .tab-pane .customer-tab .cstm-title {
              margin: 0;
              font-size: 14px;
              font-weight: normal;
              background: #f0f0f0;
              display: inline-block;
              position: relative;
              bottom: 14px;
              left: 7px;
              padding: 0 4px; }
            #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content .tab-pane .customer-tab table {
              width: 90%;
              margin: 0 auto;
              font-size: 14px; }
              #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content .tab-pane .customer-tab table .zip-code {
                position: relative;
                left: 35px; }
            #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content .tab-pane .customer-tab .tbl-payment-info td {
              border: 1px solid #ccc;
              text-align: center; }
          #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content #tab-1 .date-input {
            outline: none;
            font-size: 14px;
            padding: 0 3px; }
          #notesModal .modal-dialog .modal-content .modal-body .notes-tabs .tab-content #tab-1 .comments {
            width: 100%;
            margin-top: 15px;
            outline: none;
            resize: none;
            height: 100px;
            font-size: 15px;
            padding: 0 5px; }
      #notesModal .modal-dialog .modal-content .modal-body .notes-footer {
        padding-top: 15px; }
        #notesModal .modal-dialog .modal-content .modal-body .notes-footer .lft-side .cbk-sec {
          float: left;
          margin-right: 15px; }
          #notesModal .modal-dialog .modal-content .modal-body .notes-footer .lft-side .cbk-sec span {
            font-size: 14px; }
        #notesModal .modal-dialog .modal-content .modal-body .notes-footer .lft-side .date-sec .cstm-input {
          font-size: 14px;
          outline: none;
          padding: 0 3px; }
        #notesModal .modal-dialog .modal-content .modal-body .notes-footer .right-side {
          text-align: right;
          padding-top: 10px; }
          #notesModal .modal-dialog .modal-content .modal-body .notes-footer .right-side .cstm-btn {
            width: 85px;
            margin-bottom: 5px;
            border: 2px outset #ccc;
            background: #f3f3f3;
            outline: none;
            font-size: 14px; }
            #notesModal .modal-dialog .modal-content .modal-body .notes-footer .right-side .cstm-btn:hover {
              cursor: pointer;
              border: 2px inset #ccc; }

.modal {
  text-align: center;
  padding: 0 !important; }

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; }

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle; }

#misc-info .inner-misc .misc-grid {
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 15px;
  float: left;
  width: 100%;
  padding: 15px; }
  #misc-info .inner-misc .misc-grid .misc-title {
    margin: 0;
    font-size: 14px;
    position: absolute;
    top: -7px;
    background: #f0f0f0;
    display: inline;
    padding: 0 4px; }
  #misc-info .inner-misc .misc-grid .mise-left-grid .cstm-input {
    text-align: right; }
  #misc-info .inner-misc .misc-grid .rw label {
    width: 120px;
    font-size: 15px; }
  #misc-info .inner-misc .misc-grid .rw .cstm-input {
    outline: none;
    font-size: 15px;
    padding: 0 3px; }
  #misc-info .inner-misc .misc-grid .table-sec {
    float: left;
    width: 83%;
    border: 1px inset #ccc;
    padding: 7px;
    height: 210px; }
    #misc-info .inner-misc .misc-grid .table-sec table {
      width: 100%; }
      #misc-info .inner-misc .misc-grid .table-sec table th {
        border: 1px solid #807e7e;
        font-weight: normal;
        font-size: 14px;
        padding: 3px 5px; }
      #misc-info .inner-misc .misc-grid .table-sec table td {
        border: 1px solid #807e7e;
        font-weight: normal;
        font-size: 14px;
        padding: 3px 5px; }
      #misc-info .inner-misc .misc-grid .table-sec table .td-rw:hover {
        background: #3199fc;
        color: #fff; }
      #misc-info .inner-misc .misc-grid .table-sec table .date-th {
        text-align: center;
        width: 110px; }
      #misc-info .inner-misc .misc-grid .table-sec table .date-td {
        text-align: center; }
      #misc-info .inner-misc .misc-grid .table-sec table .charges-th, #misc-info .inner-misc .misc-grid .table-sec table .charge-td {
        text-align: right;
        width: 110px; }
  #misc-info .inner-misc .misc-grid .misc-btn-sec {
    float: right; }
    #misc-info .inner-misc .misc-grid .misc-btn-sec .cstm-btn {
      display: block;
      width: 100px;
      margin-bottom: 5px;
      border: 2px outset #ccc;
      background: #f3f3f3;
      outline: none; }
      #misc-info .inner-misc .misc-grid .misc-btn-sec .cstm-btn:hover {
        border: 2px inset #ccc;
        cursor: pointer; }

        .payments-form {
  float: left;
  width: 100%; }
  .payments-form .payment-first-rw {
    padding: 15px 0;
    float: left;
    width: 100%; }
    .payments-form .payment-first-rw .input-sec {
      float: left;
      width: 25%; }
      .payments-form .payment-first-rw .input-sec span {
        font-size: 14px; }
      .payments-form .payment-first-rw .input-sec .cstm-input {
        outline: none;
        font-size: 14px; }
    .payments-form .payment-first-rw .payment-btn-sec {
      float: left;
      width: 75%;
      padding-left: 30px; }
      .payments-form .payment-first-rw .payment-btn-sec ul {
        margin: 0;
        padding: 0; }
        .payments-form .payment-first-rw .payment-btn-sec ul li {
          display: inline-block; }
          .payments-form .payment-first-rw .payment-btn-sec ul li .cstm-btn {
            background: #f0f0f0;
            outline: none; }
            .payments-form .payment-first-rw .payment-btn-sec ul li .cstm-btn:hover {
              cursor: pointer;
              border: 2px inset; }
        .payments-form .payment-first-rw .payment-btn-sec ul .recept-sec {
          float: right; }
          .payments-form .payment-first-rw .payment-btn-sec ul .recept-sec .btn-icon {
            display: inline-block;
            vertical-align: middle; }
          .payments-form .payment-first-rw .payment-btn-sec ul .recept-sec span {
            display: block; }
          .payments-form .payment-first-rw .payment-btn-sec ul .recept-sec .txt-sec {
            display: inline-block;
            vertical-align: middle;
            line-height: 15px;
            font-size: 14px; }
  .payments-form .payments-tabs {
    float: left;
    width: 100%; }
    .payments-form .payments-tabs .nav-tabs li a {
      background: transparent;
      border: 2px inset #fff;
      border-bottom: 0;
      color: #282828;
      font-size: 14px;
      padding: 5px 10px; }
    .payments-form .payments-tabs .nav-tabs li .active {
      border: 2px outset #fff;
      border-bottom: 0; }
    .payments-form .payments-tabs #paymentTabContent {
      border: 1px outset #fff;
      padding: 20px 10px 15px; }
      .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row {
        margin: 0; }
        .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .cstm-col .custom-col {
          padding: 0 5px; }
        .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .cstm-col .inner {
          position: relative;
          border: 1px solid #ccc;
          padding: 15px 10px 10px; }
          .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .cstm-col .inner .title {
            position: absolute;
            left: 10px;
            top: -9px;
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            background: #f0f0f0;
            padding: 0 5px; }
        .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .cstm-col .cstm-rw {
          margin-bottom: 7px; }
          .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .cstm-col .cstm-rw label {
            text-align: left;
            font-size: 15px;
            margin: 0;
            width: 125px; }
          .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .cstm-col .cstm-rw .cstm-input {
            width: 106px;
            float: right;
            text-align: right;
            padding: 0 4px;
            font-size: 14px;
            outline: none; }
        .payments-form .payments-tabs #paymentTabContent .tab-pane .inner .row .left-grid {
          padding-right: 0; }
  .payments-form .payment-table {
    float: left;
    overflow-x: scroll;
    padding-top: 12px;
    width: 100%; }
    .payments-form .payment-table .inner-table {
      width: 1400px; }
      .payments-form .payment-table .inner-table table {
        text-align: center;
        width: 100%;
        border-color: #ccc;
        font-size: 14px; }
        .payments-form .payment-table .inner-table table th {
          padding: 4px;
          font-weight: normal; }
        .payments-form .payment-table .inner-table table td {
          border: 1px solid #ccc;
          height: 22px;
          background: #fff;
          padding: 4px; }

#addPaymentModal .modal-dialog {
  width: 850px !important;
  max-width: 850px !important; }
  #addPaymentModal .modal-dialog .modal-content {
    border-radius: 0; }
    #addPaymentModal .modal-dialog .modal-content .modal-header {
      padding: 7px 15px; }
      #addPaymentModal .modal-dialog .modal-content .modal-header img {
        position: relative;
        top: 4px;
        margin-right: 5px; }
      #addPaymentModal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 14px;
        font-weight: normal; }
      #addPaymentModal .modal-dialog .modal-content .modal-header .close {
        outline: none; }
  #addPaymentModal .modal-dialog .modal-body {
    background: #f0f0f0; }
    #addPaymentModal .modal-dialog .modal-body .payments-list-grid {
      padding-bottom: 15px; }
      #addPaymentModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw label {
        font-size: 15px;
        width: 90px; }
      #addPaymentModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .cstm-input {
        outline: none;
        padding: 0 4px;
        font-size: 15px; }
      #addPaymentModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .cstm-btn {
        background: transparent;
        line-height: 19px; }
        #addPaymentModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .cstm-btn:hover {
          cursor: pointer;
          border: 2px inset #ccc; }
      #addPaymentModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .without-border {
        border: 0;
        background: #f0f0f0;
        text-align: right; }
    #addPaymentModal .modal-dialog .modal-body .cstm-col {
      padding-left: 7px;
      padding-right: 0; }
      #addPaymentModal .modal-dialog .modal-body .cstm-col .inner {
        height: 418px; }
        #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw {
          float: left;
          width: 100%; }
          #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw label {
            float: left; }
          #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .cstm-input {
            width: 74px !important;
            float: left !important; }
          #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-label, #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-input {
            color: #03007b;
            font-weight: bold; }
          #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-input {
            margin-right: 8px; }
          #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-btn {
            float: left;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            color: #03007b;
            padding: 0 5px 5px;
            background: transparent;
            outline: none; }
            #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-btn:hover {
              cursor: pointer;
              border: 2px inset #ccc; }
            #addPaymentModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-btn span {
              display: inline-block;
              position: relative;
              top: 5px;
              right: 3px; }
    #addPaymentModal .modal-dialog .modal-body .left-grid label {
      width: 99px !important; }
    #addPaymentModal .modal-dialog .modal-body .left-grid .cstm-select {
      outline: none;
      padding: 0 4px; }
    #addPaymentModal .modal-dialog .modal-body .center-grid {
      padding-right: 0; }
    #addPaymentModal .modal-dialog .modal-body .right-grid label {
      width: 110px !important; }
    #addPaymentModal .modal-dialog .modal-body .right-grid .custom-col {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-top: 1px !important; }
    #addPaymentModal .modal-dialog .modal-body .payments-foot {
      float: left;
      width: 100%;
      padding-top: 15px; }
      #addPaymentModal .modal-dialog .modal-body .payments-foot .left-col .inner {
        border: 1px solid #ccc;
        padding: 15px 10px 10px;
        width: 90%; }
        #addPaymentModal .modal-dialog .modal-body .payments-foot .left-col .inner .title {
          margin: 0;
          font-size: 14px;
          font-weight: normal;
          position: absolute;
          top: -8px;
          background: #f0f0f0;
          padding: 0 5px; }
        #addPaymentModal .modal-dialog .modal-body .payments-foot .left-col .inner span {
          font-size: 14px; }
        #addPaymentModal .modal-dialog .modal-body .payments-foot .left-col .inner .af-day-input {
          outline: none;
          width: 50px;
          text-align: right;
          padding: 0 4px; }
        #addPaymentModal .modal-dialog .modal-body .payments-foot .left-col .inner .pay-off {
          margin-left: 20px; }
        #addPaymentModal .modal-dialog .modal-body .payments-foot .left-col .inner .payoff-input {
          outline: none;
          text-align: right;
          padding: 0 4px; }
      #addPaymentModal .modal-dialog .modal-body .payments-foot .right-col {
        text-align: right;
        padding-top: 13px; }
        #addPaymentModal .modal-dialog .modal-body .payments-foot .right-col .cstm-btn {
          background: transparent;
          width: 100px;
          outline: none; }
          #addPaymentModal .modal-dialog .modal-body .payments-foot .right-col .cstm-btn:hover {
            cursor: pointer;
            border: 2px inset #ccc; }

            #depreciate-costs .inner-row {
  border: 1px solid #ccc;
  padding: 15px;
  position: relative; }
  #depreciate-costs .inner-row .veh-title {
    margin: 0;
    font-size: 14px;
    position: absolute;
    top: -9px;
    background: #f0f0f0;
    padding: 0 4px; }
  #depreciate-costs .inner-row table {
    text-align: center;
    width: 100%; }
    #depreciate-costs .inner-row table th {
      font-weight: normal;
      font-size: 15px; }
    #depreciate-costs .inner-row table td {
      font-size: 15px;
      background: #fff;
      height: 25px; }
  #depreciate-costs .inner-row .btn-sec .cstm-btn {
    background: transparent;
    width: 100px;
    margin-bottom: 7px;
    font-size: 14px;
    outline: none; }
  #depreciate-costs .inner-row .btn-sec .inline-btn .btn-cstm {
    background: transparent;
    width: 48px;
    outline: none; }
#depreciate-costs .dep-costs-sec {
  margin-top: 20px; }
  #depreciate-costs .dep-costs-sec .dept-left .cstm-rw .cstm-col {
    padding-right: 0; }
    #depreciate-costs .dep-costs-sec .dept-left .cstm-rw .cstm-col label {
      width: 20px; }
    #depreciate-costs .dep-costs-sec .dept-left .cstm-rw .cstm-col span {
      margin-bottom: 7px;
      display: inline-block; }
    #depreciate-costs .dep-costs-sec .dept-left .cstm-rw .cstm-col .cstm-input {
      outline: none;
      width: 30px;
      width: 104px;
      padding: 0 4px;
      text-align: right;
      font-size: 14px; }
  #depreciate-costs .dep-costs-sec .dept-right {
    padding: 0; }
    #depreciate-costs .dep-costs-sec .dept-right .cstm-rw {
      margin-bottom: 7px; }
      #depreciate-costs .dep-costs-sec .dept-right .cstm-rw .cstm-input {
        outline: none;
        padding: 0 4px;
        width: 97%;
        font-size: 14px; }
#depreciate-costs .floor-btn {
  background: transparent;
  line-height: 17px;
  padding: 7px 10px;
  margin-top: 20px;
  outline: none; }
  #depreciate-costs .floor-btn span {
    display: block; }

.account-payable-row {
  border: 0 !important;
  padding: 0 !important; }
  .account-payable-row .cal-grid {
    margin-bottom: 15px; }
    .account-payable-row .cal-grid .calander-grid {
      margin: 0;
      padding: 0;
      float: left;
      width: 63%; }
      .account-payable-row .cal-grid .calander-grid li {
        float: left;
        list-style: none; }
        .account-payable-row .cal-grid .calander-grid li a {
          display: block;
          border: 1px outset #ccc;
          padding: 7px 15px;
          width: 95px;
          color: #282828;
          text-align: center; }
          .account-payable-row .cal-grid .calander-grid li a:hover {
            text-decoration: none;
            border: 1px inset #ccc;
            background: #e3e3e3; }
        .account-payable-row .cal-grid .calander-grid li .active {
          background: #e3e3e3; }
    .account-payable-row .cal-grid .cal-btn-sec {
      margin-right: 10px;
      float: left; }
      .account-payable-row .cal-grid .cal-btn-sec .cal-btn {
        width: 100px;
        height: 80px;
        background: transparent;
        outline: none;
        float: left; }
      .account-payable-row .cal-grid .cal-btn-sec .arrows {
        float: left;
        height: 80px; }
        .account-payable-row .cal-grid .cal-btn-sec .arrows span {
          display: block;
          height: 40px;
          border: 2px outset;
          padding: 4px 2px; }
          .account-payable-row .cal-grid .cal-btn-sec .arrows span:hover {
            border: 2px inset; }
    .account-payable-row .cal-grid .filter-btn {
      float: left; }
      .account-payable-row .cal-grid .filter-btn .filter {
        background: transparent;
        outline: none;
        text-align: center;
        height: 80px; }
        .account-payable-row .cal-grid .filter-btn .filter span {
          display: block; }

.sidebar-with-btns li {
  margin-bottom: 4px !important; }
  .sidebar-with-btns li .img-blk-btn span {
    display: block; }
  .sidebar-with-btns li .cstm-btn {
    background: transparent;
    outline: none;
    font-size: 14px;
    width: 155px;
    padding-top: 2px;
    padding-bottom: 2px; }

#addVehicleCostModal .modal-dialog {
  width: 700px;
  max-width: 700px; }
  #addVehicleCostModal .modal-dialog .modal-content {
    border-radius: 0; }
    #addVehicleCostModal .modal-dialog .modal-content .modal-header {
      padding: 5px 10px; }
      #addVehicleCostModal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 14px;
        font-weight: normal; }
    #addVehicleCostModal .modal-dialog .modal-content .modal-body {
      background: #f0f0f0; }
      #addVehicleCostModal .modal-dialog .modal-content .modal-body .stock-rw .cstm-col label {
        margin-right: 15px;
        font-size: 14px; }
      #addVehicleCostModal .modal-dialog .modal-content .modal-body .stock-rw .cstm-col span {
        color: #00136c;
        font-weight: bold;
        font-size: 12px; }
      #addVehicleCostModal .modal-dialog .modal-content .modal-body .stock-rw .btn-sec {
        text-align: right; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .stock-rw .btn-sec .cstm-btn {
          background: transparent;
          width: 80px;
          outline: none; }
      #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw {
        padding: 15px 0; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .left-grid .cstm-col {
          padding-right: 0; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .left-grid .cstm-col label {
            font-size: 14px;
            margin-bottom: 0; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .left-grid .cstm-col .cstm-input {
            width: 130px;
            outline: none;
            padding: 0 4px;
            font-size: 14px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .right-grid .cstm-col {
          padding-right: 0; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .right-grid .cstm-col label {
            font-size: 14px;
            margin-bottom: 0;
            display: block; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .right-grid .cstm-col .cstm-input {
            width: 100%;
            outline: none;
            padding: 0 4px;
            font-size: 14px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .right-grid .amount-col {
          padding-right: 15px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .right-grid .amount-col .amount-label {
            text-transform: uppercase;
            display: inline-block;
            text-decoration: underline;
            font-size: 12px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .amount-rw .right-grid .amount-col .cstm-input {
            text-align: right;
            color: #c00;
            font-weight: bold; }
      #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner {
        border: 1px solid #ccc;
        position: relative;
        padding: 10px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .cstm-title {
          font-size: 14px;
          font-weight: normal;
          position: absolute;
          top: -9px;
          left: 9px;
          background: #f0f0f0;
          padding: 0 4px;
          margin: 0; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .left-grid .cstm-col label {
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 0;
          font-size: 14px;
          width: 100%; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .left-grid .cstm-col .cstm-input {
          width: 100% !important;
          outline: none;
          padding: 0 4px;
          margin-bottom: 7px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .left-grid .right-align {
          padding: 0; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .left-grid .right-align .cstm-input {
            text-align: right; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .left-grid .total-sec span {
          text-align: right;
          display: block;
          text-transform: uppercase;
          font-size: 13px;
          text-decoration: underline; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid {
          padding-right: 0; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .first-rw span {
            text-transform: uppercase;
            font-size: 14px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .cstm-rw label {
            width: 67px;
            text-align: right;
            padding-right: 5px;
            font-size: 14px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .cstm-rw .cstm-input {
            outline: none;
            padding: 0 4px;
            font-size: 14px;
            width: 200px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .cstm-rw .cstm-select {
            outline: none;
            padding: 0 4px;
            font-size: 14px;
            width: 200px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .state-rw .cstm-select {
            width: 80px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .state-rw span {
            font-size: 14px;
            padding: 0 4px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .right-grid .state-rw .cstm-input {
            width: 80px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .category-rw {
          padding: 7px 15px 0;
          width: 100%; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .category-rw .cstm-col label {
            font-size: 14px;
            margin: 0;
            float: left;
            width: 82px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .category-rw .cstm-col .cstm-input {
            outline: none;
            padding: 0 4px;
            width: 112px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw {
          padding: 15px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .left-grid {
            padding-right: 0; }
            #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .left-grid label {
              width: 100%;
              font-size: 14px;
              margin: 0; }
            #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .left-grid .cstm-input {
              width: 100%;
              outline: none;
              padding: 0 4px;
              margin-bottom: 7px;
              font-size: 14px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .right-grid {
            padding-right: 22px; }
            #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .right-grid .cstm-col {
              padding: 0 5px; }
              #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .right-grid .cstm-col label {
                font-size: 14px;
                margin: 0; }
              #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .right-grid .cstm-col .cstm-input {
                outline: none;
                padding: 0 4px;
                font-size: 14px;
                text-align: right;
                width: 100%;
                margin-bottom: 7px; }
            #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .right-grid .total-ticket-sec label {
              float: left;
              width: 191px;
              text-align: right;
              font-size: 14px;
              text-transform: uppercase;
              text-decoration: underline;
              margin-right: 9px;
              margin-bottom: 0; }
            #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .right-grid .description-rw .right-grid .total-ticket-sec .cstm-input {
              float: left;
              width: 88px;
              font-size: 14px;
              outline: none;
              padding: 0 4px;
              text-align: right; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .technician-sec {
          text-align: right;
          padding-right: 0;
          padding-top: 10px; }
        #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .tech-dropdown-sec {
          padding-top: 10px; }
          #addVehicleCostModal .modal-dialog .modal-content .modal-body .details-sec .inner .tech-dropdown-sec .cstm-input {
            width: 100%;
            outline: none;
            font-size: 14px;
            padding: 0 4px; }
#buyer-guide-model .modal-dialog {
  width: 650px;
  max-width: 650px; }
  #buyer-guide-model .modal-dialog .modal-content {
    border-radius: 0; }
    #buyer-guide-model .modal-dialog .modal-content .modal-header {
      padding: 7px 15px; }
      #buyer-guide-model .modal-dialog .modal-content .modal-header img {
        position: relative;
        top: 3px; }
      #buyer-guide-model .modal-dialog .modal-content .modal-header .modal-title {
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        float: left;
        display: block;
        width: 100%;
        padding-left: 7px; }
      #buyer-guide-model .modal-dialog .modal-content .modal-header .close {
        padding: 0;
        position: absolute;
        top: 18px;
        right: 25px; }
    #buyer-guide-model .modal-dialog .modal-content .modal-body {
      background: #f0f0f0; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .stock-rw label {
        font-size: 14px; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .stock-rw .cstm-input {
        outline: none;
        font-size: 14px;
        padding: 2px 4px; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .stock-rw .cstm-btn {
        background: #f0f0f0;
        border: 2px outset;
        padding: 1px 10px; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .stock-rw .cstm-btn:hover {
          cursor: pointer;
          border: 2px inset; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .vehicle-make-rw {
        padding: 7px 0; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .vehicle-make-rw ul {
          padding: 0;
          margin: 0; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .vehicle-make-rw ul li {
            display: inline-block;
            width: 23%;
            margin-right: 3px; }
            #buyer-guide-model .modal-dialog .modal-content .modal-body .vehicle-make-rw ul li label {
              display: block;
              margin-bottom: 1px;
              font-size: 14px; }
            #buyer-guide-model .modal-dialog .modal-content .modal-body .vehicle-make-rw ul li .cstm-input {
              width: 100%;
              font-size: 14px;
              padding: 0 4px;
              outline: none; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .radio-sec .rw .cstm_rad {
        position: relative;
        top: 1px; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .radio-sec .rw label {
        margin: 0;
        font-size: 15px; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .radio-sec .rw label:hover {
          cursor: pointer; }
      #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form {
        padding-top: 15px; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner {
          border: 1px solid #ccc;
          padding: 10px 5px;
          position: relative; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .war-title {
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            position: absolute;
            left: 8px;
            top: -9px;
            background: #f0f0f0;
            padding: 0 4px; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul {
            margin: 0;
            padding: 0; }
            #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li {
              display: inline-block; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li label {
                text-transform: uppercase; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li label, #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li span {
                font-size: 14px; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li .dlr-pay-title {
                margin-left: 7px; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li .war_rad {
                position: relative;
                top: 2px; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li .cstm-input {
                width: 70px; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row {
            margin: 0; }
            #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid {
              padding-right: 0; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid:first-child {
                padding-left: 0; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid label {
                margin-bottom: 2px;
                font-size: 14px; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid .cstm-input {
                width: 100%;
                outline: none;
                margin-bottom: 7px;
                padding: 0 5px;
                font-size: 15px; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer ul {
          padding: 0;
          margin: 0; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer ul li {
            display: inline-block; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list {
          padding: 7px 0; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li {
            margin-right: 15px; }
            #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li label {
              font-size: 14px;
              margin: 0; }
              #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li label:hover {
                cursor: pointer; }
            #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li .wr-cbk {
              position: relative;
              top: 1px; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .btn-sec .cstm-btn {
          background: #f0f0f0;
          font-size: 15px;
          width: 115px; }
          #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .btn-sec .cstm-btn:hover {
            cursor: pointer;
            border: 2px inset; }
        #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .btn-sec .pos-right {
          float: right; }
#Amo-guide-model .modal-dialog {
  width: 1150px;
  max-width: 1150px; }
    #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form {
        padding-top: 15px; }
        #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner {
          border: 1px solid #ccc;
          padding: 10px 5px;
          position: relative; }
          #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .war-title {
            margin: 0;
            font-size: 14px;
            font-weight: normal;
            position: absolute;
            left: 8px;
            top: -9px;
            background: #f0f0f0;
            padding: 0 4px; }
          #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul {
            margin: 0;
            padding: 0; }
            #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li {
              display: inline-block; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li label {
                text-transform: uppercase; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li label, #buyer-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li span {
                font-size: 14px; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li .dlr-pay-title {
                margin-left: 7px; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li .war_rad {
                position: relative;
                top: 2px; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .cstm-rw ul li .cstm-input {
                width: 70px; }
          #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row {
            margin: 0; }
            #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid {
              padding-right: 0; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid:first-child {
                padding-left: 0; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid label {
                margin-bottom: 2px;
                font-size: 14px; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .inner .stytem-grid-sec .row .cstm-grid .cstm-input {
                width: 100%;
                outline: none;
                margin-bottom: 7px;
                padding: 0 5px;
                font-size: 15px; }
        #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer ul {
          padding: 0;
          margin: 0; }
          #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer ul li {
            display: inline-block; }
        #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list {
          padding: 7px 0; }
          #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li {
            margin-right: 15px; }
            #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li label {
              font-size: 14px;
              margin: 0; }
              #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li label:hover {
                cursor: pointer; }
            #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .first-ul-list li .wr-cbk {
              position: relative;
              top: 1px; }
        #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .btn-sec .cstm-btn {
          background: #f0f0f0;
          font-size: 15px;
          width: 115px; }
          #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .btn-sec .cstm-btn:hover {
            cursor: pointer;
            border: 2px inset; }
        #Amo-guide-model .modal-dialog .modal-content .modal-body .warranty-form .warranty-footer .btn-sec .pos-right {
          float: right; }

          #paymentScreenModal .modal-dialog {
            width: 1250px;
            max-width: 1250px; }
            #paymentScreenModal .modal-dialog .modal-content {
              border-radius: 0; }
              #paymentScreenModal .modal-dialog .modal-content .modal-header {
                padding: 7px 15px; }
                #paymentScreenModal .modal-dialog .modal-content .modal-header img {
                  position: relative;
                  top: 4px;
                  margin-right: 5px; }
                #paymentScreenModal .modal-dialog .modal-content .modal-header .modal-title {
                  font-size: 14px;
                  font-weight: normal; }
                #paymentScreenModal .modal-dialog .modal-content .modal-header .close {
                  outline: none; }
            #paymentScreenModal .modal-dialog .modal-body {
              background: #f0f0f0; }
              #paymentScreenModal .modal-dialog .modal-body .payments-list-grid {
                padding-bottom: 15px; }
                #paymentScreenModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw label {
                  font-size: 15px;
                  width: 90px; }
                #paymentScreenModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .cstm-input {
                  outline: none;
                  padding: 0 4px;
                  font-size: 15px; }
                #paymentScreenModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .cstm-btn {
                  background: transparent;
                  line-height: 19px; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .cstm-btn:hover {
                    cursor: pointer;
                    border: 2px inset #ccc; }
                #paymentScreenModal .modal-dialog .modal-body .payments-list-grid .cstm-col .rw .without-border {
                  border: 0;
                  background: #f0f0f0;
                  text-align: right; }
              #paymentScreenModal .modal-dialog .modal-body .cstm-col {
                padding-left: 7px;
                padding-right: 0; }
                #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner {
                  height: 418px; }
                  #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw {
                    float: left;
                    width: 100%; }
                    #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw label {
                      float: left; }
                    #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .cstm-input {
                      width: 74px !important;
                      float: left !important; }
                    #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-label, #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-input {
                      color: #03007b;
                      font-weight: bold; }
                    #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-input {
                      margin-right: 8px; }
                    #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-btn {
                      float: left;
                      text-transform: uppercase;
                      font-size: 12px;
                      font-weight: bold;
                      color: #03007b;
                      padding: 0 5px 5px;
                      background: transparent;
                      outline: none; }
                      #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-btn:hover {
                        cursor: pointer;
                        border: 2px inset #ccc; }
                      #paymentScreenModal .modal-dialog .modal-body .cstm-col .inner .cstm-rw .amt-btn span {
                        display: inline-block;
                        position: relative;
                        top: 5px;
                        right: 3px; }
              #paymentScreenModal .modal-dialog .modal-body .left-grid label {
                width: 99px !important; }
              #paymentScreenModal .modal-dialog .modal-body .left-grid .cstm-select {
                outline: none;
                padding: 0 4px; }
              #paymentScreenModal .modal-dialog .modal-body .center-grid {
                padding-right: 0; }
              #paymentScreenModal .modal-dialog .modal-body .right-grid label {
                width: 110px !important; }
              #paymentScreenModal .modal-dialog .modal-body .right-grid .custom-col {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                padding-top: 1px !important; }
              #paymentScreenModal .modal-dialog .modal-body .payments-foot {
                float: left;
                width: 100%;
                padding-top: 15px; }
                #paymentScreenModal .modal-dialog .modal-body .payments-foot .left-col .inner {
                  border: 1px solid #ccc;
                  padding: 15px 10px 10px;
                  width: 90%; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-foot .left-col .inner .title {
                    margin: 0;
                    font-size: 14px;
                    font-weight: normal;
                    position: absolute;
                    top: -8px;
                    background: #f0f0f0;
                    padding: 0 5px; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-foot .left-col .inner span {
                    font-size: 14px; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-foot .left-col .inner .af-day-input {
                    outline: none;
                    width: 50px;
                    text-align: right;
                    padding: 0 4px; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-foot .left-col .inner .pay-off {
                    margin-left: 20px; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-foot .left-col .inner .payoff-input {
                    outline: none;
                    text-align: right;
                    padding: 0 4px; }
                #paymentScreenModal .modal-dialog .modal-body .payments-foot .right-col {
                  text-align: right;
                  padding-top: 13px; }
                  #paymentScreenModal .modal-dialog .modal-body .payments-foot .right-col .cstm-btn {
                    background: transparent;
                    width: 100px;
                    outline: none; }
                    #paymentScreenModal .modal-dialog .modal-body .payments-foot .right-col .cstm-btn:hover {
                      cursor: pointer;
                      border: 2px inset #ccc; }
                      tr:hover td {background-color:#fbb30a !important;}  
                      .highlight {
                        background: #fbb30a ;
                      }
                      @keyframes fa-blink {
                        0% {opacity: 1;}
                        100% {opacity: 0.4;}
                    }
                    
                    .is-online {
                        animation: fa-blink .5s linear infinite;
                        color: rgb(1, 189, 42);
                    }
  /* # sourceMappingURL=style.css.map */
  