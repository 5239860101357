header {
	position: fixed;
    left: 0;
    right: 0;
    background: #4e5359;
    z-index: 99;
    color: #fff;
}
#header-caro {
    width: 95%;
    margin: 0 auto;
}
header .owl-dots, footer .owl-dots {
	display: none;
}
header .owl-stage-outer, footer .owl-stage-outer {
	z-index: 99;
}
header .owl-theme .item, footer .owl-theme .item {
	padding: 0 15px;
	text-align: center;
	text-transform: uppercase;
}
header .owl-theme .item a, footer .owl-theme .item a {
	color: #fff;
}
header .owl-theme .item a:hover,
footer .owl-theme .item a:hover {
	text-decoration: none;
}
header .owl-nav, footer .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}
header .owl-item, footer .owl-item {
	padding: 15px 0;
}
header .owl-item.active, footer .owl-item.active {
	/*background: #42454a;*/
}
header .owl-nav button, header .owl-nav button {
	position: relative;
    bottom: 3px;
}
header .owl-nav .owl-next {
	right: 0;
}
header .owl-nav button:active, header .owl-nav button:focus,
footer .owl-nav button:active, footer .owl-nav button:focus {
	outline: none;
}
header .owl-stage-outer, footer .owl-stage-outer {
	width: 96%;
    margin: 0 auto;
}
header .owl-prev, footer .owl-prev {
	float: left;
	color: #fff !important;
	font-size: 30px !important;
	line-height: 38px !important;
}
header .owl-next, footer .owl-next {
	float: right;
	color: #fff !important;
	font-size: 30px !important;

}
header .header-drop-menu {
	position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    color: #fff;
    font-size: 20px;
    display: block;
    width: 20px;
    text-align: center;
}
header .header-dropdown-menus {
	background: #fff;
    max-width: 250px;
    position: absolute;
    z-index: 99;
    width: 100%;
    right: 2px;
    display: none;
}
header .header-dropdown-menus ul {
	padding: 10px 0 15px;
	background: #42454af2;
	margin-bottom: 0;
}
header .header-dropdown-menus ul li {
	display: block;
}
header .header-dropdown-menus ul li a {
	display: block;
	text-transform: uppercase;
	color: #fff;
	padding: 4px 20px;
}
header .header-dropdown-menus ul li a:hover {
	text-decoration: none;
	background: #4e5359;
}
.custom-spacer {
	width: 100%;
    padding: 25px;
}
.products-sec {
	padding: 20px 0 40px;
	float: left;
	width: 100%;
}
.products-sec .custom-col {
	padding: 15px;
}
.products-sec .custom-col .inner {
	padding: 15px 0;
	background: #f3f3f3;
	border: 1px solid #e4e4e4;
	text-align: center;
}
.products-sec .custom-col .product-title {
	border: 1px solid #e4e4e4;
	border-top: 0;
    padding: 4px 4px;
    width: 100%;
    display: block;
    text-align: center;
    color: #616060;
    font-weight: 600;
    text-transform: capitalize;
    text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.products-sec .img-responsive, .products-sec .custom-col .inner {
	transition: all ease-in .3s;
	-webkit-transition: all ease-in .3s;
	-moz-transition: all ease-in .3s;
	-o-transition: all ease-in .3s;
}
.products-sec .custom-col a {
	display: block;
}
.products-sec .custom-col a:hover .inner {
	background: #fbfbfb;
}
.products-sec .custom-col a:hover .img-responsive {
	transform: scale(1.1);
}
.products-sec .custom-col a:hover,
.products-sec .custom-col a:active,
.products-sec .custom-col a:focus {
	text-decoration: none;
}
.products-sec .product-left-side {
	padding: 0;
}
.products-sec .product-right-side .inner {
    border: 1px solid #ccc;
}
.products-sec .product-left-side, .products-sec .product-right-side {
	float: left;
}
.products-sec .product-right-side .collapse-title {
	border-bottom: 1px solid rgba(0,0,0,.125);
    padding: 10px 15px;
    font-size: 18px;
    color: #525252;
    margin-bottom: 5px;
}
.products-sec .product-right-side .collapse-title:hover {
	cursor: pointer;
}
.products-sec .product-right-side .card.card-body {
	border-radius: 0;
	float: left;
	width: 100%;
	border: 0;
}
.products-sec .product-right-side .card.card-body .custom-rw {
	margin-bottom: 15px;
}
.products-sec .product-right-side .card.card-body .title {
	float: left;
	font-size: 16px;
	color: #525252;
}
.products-sec .product-right-side .card.card-body .price {
	float: right;
}
.product-foot-sec {
	float: left;
	width: 100%;
	padding: 10px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 35px;
}

.product-foot-sec img {
	max-width: 100%;
	width: 100px;
}
.product-foot-sec .form-sec {
	width: 100%;
}
.product-foot-sec table th {
	border: 0;
	padding: 0 20px 0 0;
	font-weight: 600;
	color: #616060;
}
.product-foot-sec table .img-td {
	padding-top: 0;
	text-align: center;
}
.product-foot-sec table .img-td img {
	width: 64px;
}
.product-foot-sec table td {
	padding-left: 0;
	padding-bottom: 0;
	border: 0;
}
.mob-cancel {
	width: 100px;
}
.hours .cstm-input, .hours1 .cstm-input {
	width: 30px;
    border: 0;
    text-align: center;
    outline: none;
}
.in-de-btn {
	font-weight: bold;
	color: #616060;
	font-size: 22px;
}
.in-de-btn:hover {
	text-decoration: none;
}
footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #4e5359;
}

::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: rgba(142, 142, 142, 0.1);
}
::-webkit-scrollbar-thumb {
  background: rgba(181, 180, 180, 0.5);
}
header .header-nav ul {
    padding: 15px 0;
    margin: 0;
}
header .header-nav ul li {
	display: inline-block;
	width: 18%;
    text-align: center;
}
header .header-nav ul li a {
	display: block;
	color: #fff;
	text-transform: uppercase;
}
header .header-nav ul li a:hover {
	text-decoration: none;
}
.owl-next.disabled, .owl-prev.disabled {
	display: none !important;
}

.header-desktop-menu ul {
	padding: 0;
	margin: 0;
}
.header-desktop-menu ul li {
	display: inline-block;
}
.header-desktop-menu ul li a {
	display: block;
	padding: 18px 5px;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
}
.header-desktop-menu ul li a:hover {
	text-decoration: none;
	color: #007bff;
}
.sidebar-content ul {
	 margin: 0;
    padding-left: 18px;
}
.sidebar-content ul li {
	display: block;
	line-height: 30px;
}
.sidebar-content ul li a {
	display: inline-block;
	color: #525252;
}
.sidebar-content ul li a:hover {
	text-decoration: none;
	color: #000;
}
.product-left-side .inner-scroll {
	width: 100%;
}
.active-acc-sec {
	padding-top: 40px;
}
.active-acc-sec a:hover {
	text-decoration: none;
}
.cstm-form-row-sec {
	padding-right: 15px;
}
.customer-form-sec {
	height: auto !important;
	padding-bottom: 20px !important;
}
.customer-form-sec .inner-scroll {
	overflow-x: hidden;
}
.customer-form-sec .inner-scroll .zip-code-sec {
	padding-top: 10px;
}

.tree, .tree ul {
	margin:0;
	padding:0;
	list-style:none
}
.tree ul {
    margin-left:1em;
    position:relative
}
.tree ul ul {
    margin-left:.5em
}
.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid
}
.tree li {
    margin:0;
    padding:0 1em;
    line-height:2em;
    color:#369;
    font-weight:700;
    position:relative
}
.tree ul li:before {
    content:"";
    display:block;
    width:10px;
    height:0;
    border-top:1px solid;
    margin-top:-1px;
    position:absolute;
    top:1em;
    left:0
}
.tree ul li:last-child:before {
    background:#fff;
    height:auto;
    top:1em;
    bottom:0
}
.indicator {
    margin-right:5px;
}
.tree li a {
    text-decoration: none;
    color:#369;
}
.tree li button, .tree li button:active, .tree li button:focus {
    text-decoration: none;
    color:#369;
    border:none;
    background:transparent;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    outline: 0;
}

/*
 *--------------------------------------------------
 * Responsive Settings
 * -------------------------------------------------
 */
 @media(min-width: 1327px) {
	.product-left-side {
		height: 500px
	}
	.product-left-side .inner-scroll {
	    float: left;
	    clear: both;
	    overflow-y: scroll;
	    overflow-x: hidden;
	    height: 500px;
	}
}
@media(min-width: 1200px) and (max-width: 1326px) {
	.product-left-side {
		height: 415px
	}
	.product-left-side .inner-scroll {
	    float: left;
	    clear: both;
	    overflow-y: scroll;
	    height: 415px;
	}
}
@media(min-width: 1200px) {
	.header-caro-2 {
		display: none;
	}
	.product-right-side #collapseExample {
	    height: 86%;
    	overflow-y: scroll;	
	}
	.mobile-btn-sec {
		display: none;
	}
	.product-foot-sec table {
	    background: #f9f9f9;
	    padding: 15px 0;
	    border: 1px solid #ccc;
	    width: 100%;
	}
	.product-left-side {
		height: 390px;
		overflow: hidden;
	}
	.product-left-side .inner-scroll {
		height: 100%;
		overflow-y: scroll;
	}
	.products-sec .custom-col .inner {
		padding: 10px 0;
	}
	.product-foot-sec .container-fluid {
		padding: 0;
	}
	.product-foot-sec table td {
    	width: 200px;
	}
	.product-foot-sec table td button {
	    position: relative;
    	bottom: 8px;
	}
}
@media(max-width: 1199px) {
	.header-desktop-menu {
		display: none;
	}
	.desktop-btn-sec {
		display: none;
	}
	.product-foot-sec {
		position: fixed;
		bottom: 54px;
		background: #fff;
		border-top: 1px solid #ccc;
	}
}
@media(max-width: 1024px) {
	.products-sec .custom-col { 
		padding-right: 0;
	}
}
@media(min-width: 992px) and (max-width: 1199px) {
	.product-left-side {
		height: 510px
	}
	.product-left-side .inner-scroll {
	    float: left;
	    clear: both;
	    overflow-y: scroll;
	    height: 510px;
	}
}
@media(min-width: 992px) {
	.mob-cancel {
		display: none !important;
	}
}
@media(max-width: 991px) {
	.dsk-cancel {
		display: none !important;
	}
}
@media(min-width: 768px) {
	.products-sec .custom-col {
		width: 20%;
		float: left;
	    padding-top: 0;
	}
	.products-sec .img-responsive {
		width: 64px;
	}
	.products-sec .product-right-side .inner {
	    position: fixed;
	    top: 70px;
	    width: 22%;
	}
}
@media(min-width: 992px) and (max-width: 1199px) {
	.products-sec .product-right-side .card.card-body .price {
		width: 30%;
		padding-left: 2%;
		font-size: 14px;
	}
}
@media(min-width: 768px) and (max-width: 1199px) {
	.products-sec .custom-col .product-title {
		font-size: 14px;
	}
	.products-sec .container {
		max-width: 100%;
	}
	.products-sec .product-right-side .card.card-body .title {
		width: 60%;
		font-size: 14px;
		text-overflow: ellipsis;
	    overflow: hidden;
	    white-space: nowrap;
	    padding-right: 5px;
	}	
	.products-sec .product-right-side .card.card-body .price {
		font-size: 14px;
		width: 40%;
		text-align: right;
	}
}
@media(min-width: 768px) and (max-width: 991px) {
	.product-foot-sec table th {
		font-size: 14px;
	}
	.hours .cstm-input, .hours1 .cstm-input {
		width: 22px;
	}
	.products-sec .product-left-side {
		height: 775px;
		overflow: hidden;
	}
	.products-sec .inner-scroll {
		overflow-y: scroll;
		height: 100%;
	}
	header .header-drop-menu {
		right: 15px;
	}
}
@media(max-width: 767px) {
	.products-sec .product-right-side {
		width: 100%;
		clear: both;
	}
	.products-sec .product-right-side {
	    max-width: 100%;
	    margin-bottom: 40px;
	    padding: 0;
	}
	.customer-form-sec {
		width: 100%;
	}
	.products-sec .container {
		max-width: 95%;
	}
	.customer-form-sec {
		max-width: 100% !important;
	}
	.cstm-form-row-sec {
		padding-right: 0;
	}
	.header-caro-2 {
		width: 85% !important;
	}
}
@media(min-width: 600px) and (max-width: 767px) {
	header .owl-theme .item {
		text-align: left;
	}
}
@media(max-width: 360px) {
	.customer-form-btn-sec button {
		width: 100%;
		margin-bottom: 10px;
		border-radius: 0;
		text-transform: uppercase;
	}
}